import React, { memo } from 'react'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
  },
  linkButton: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em',
    },
  },
}))
const InnerLink = ({ title, url, variant, color, disabled }) => {
  const classes = useStyles()
  return (
    <Link to={url} className={classes.root}>
      <Button className={classes.linkButton} variant={variant || 'outlined'} color={color || 'secondary'} disabled={disabled || false}>
        {title}
      </Button>
    </Link>
  )
}
export default memo(InnerLink)
