import React, { memo, useRef } from 'react'
import { Link } from 'gatsby'
import AliceCarousel from 'react-alice-carousel'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import { makeStyles } from '@material-ui/core/styles'

const responsive = {
  0: { items: 1 },
  650: { items: 2 },
  1024: { items: 3 },
  1440: { items: 4 },
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
  },
  imgWrapper: {
    textAlign: 'center',
    padding: '10px',
    paddingBottom: '7px',
    '&:hover': {
      '& $imgCaption': {
        opacity: 1,
      },
    },
  },
  imgCaption: {
    position: 'absolute',
    width: `calc(100% - 20px)`,
    height: `calc(100% - 20px)`,
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.73))',
    top: '10px',
    left: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    color: '#fff',
    opacity: 0,
    transition: theme.transitions.create(['opacity'], { duration: 700 }),
  },
  captionText: {
    border: '4px double rgba(255,255,255,.8)',
    padding: '8px 15px',
    fontSize: '1.3em',
  },
  carouselWrapper: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  imgLink: {
    textDecoration: 'none',
  },
}))
const LinkCarousel = ({ imgArray }) => {
  const classes = useStyles()

  const aliceRef = useRef()

  return (
    <>
      <AliceCarousel
        ref={aliceRef}
        responsive={responsive}
        autoPlay={true}
        autoPlayControls={false}
        autoPlayInterval={2000}
        autoPlayStrategy='default'
        infinite={true}
        mouseTracking={false}
        disableDotsControls={true}
        disableButtonsControls={true}
      >
        {imgArray.map((imgObj, index) => (
          <div className={classes.carouselWrapper}>
            <ButtonBase key={`${imgObj.url}-${index}`} focusRipple className={classes.image}>
              <Link to={imgObj.url} className={classes.imgLink}>
                <Card elevation={4}>
                  <CardMedia>
                    <div className={classes.imgWrapper}>
                      <picture>
                        <source type='image/webp' media='(max-width:479px)' srcSet={imgObj.img.xs.src} />
                        <source type='image/webp' media='(max-width:759px)' srcSet={imgObj.img.xs.src} />
                        <source type='image/webp' media='(max-width:959px)' srcSet={imgObj.img.thumb.src} />
                        <source type='image/webp' media='(max-width:1279px)' srcSet={imgObj.img.xs.src} />
                        <source type='image/webp' media='(max-width:1439px)' srcSet={imgObj.img.thumb.src} />
                        <source type='image/webp' media='(min-width:1440px)' srcSet={imgObj.img.thumb.src} />
                        <img
                          className={classes.root}
                          // loading='lazy'
                          src={imgObj.img.original.src}
                          alt={imgObj.alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
                        />
                      </picture>
                      <div className={classes.imgCaption}>
                        <Typography className={classes.captionText}>{imgObj.alt || 'Erez Kaynak'}</Typography>
                      </div>
                    </div>
                  </CardMedia>
                </Card>
              </Link>
            </ButtonBase>
          </div>
        ))}
      </AliceCarousel>
      <Box textAlign='center'>
        <IconButton color='secondary' aria-label='Önceki' onClick={() => aliceRef.current.slidePrev()}>
          <SkipPreviousIcon />
        </IconButton>
        <IconButton color='secondary' aria-label='Sonraki' onClick={() => aliceRef.current.slideNext()}>
          <SkipNextIcon />
        </IconButton>
      </Box>
    </>
  )
}
export default memo(LinkCarousel)
