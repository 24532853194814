import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
import SectionStyle1 from '../components/curves/SectionStyle1'
import YoutubeWrapper from '../contents/home/YoutubeWrapper'
import SEO from '../layouts/Seo'
import LinkCarousel from '../components/Carousel/LinkCarousel'

const IndexPage = () => {
  const { getImageByName } = useStore()
  const imgSlideArray = [
    { img: getImageByName('304paslanmazhasirtel'), url: '304-kalite-paslanmaz-hasir-tel', alt: '304 Kalite Paslanmaz Hasır Tel' },
    { img: getImageByName('316paslanmazhasirtel'), url: '316-kalite-paslanmaz-hasir-tel', alt: '316 Kalite Paslanmaz Hasır Tel' },
    { img: getImageByName('galvanizhasirtel9'), url: 'galvaniz-hasir-tel', alt: 'Galvaniz Hasır Tel' },
    { img: getImageByName('celikhasirtel3'), url: 'celik-hasir-tel-imalati', alt: 'Çelik Hasır Tel İmalatı' },
    { img: getImageByName('paslanmazhasirtel5'), url: 'asansor-seperator-teli', alt: 'Asansör Seperatör Hasır Tel İmalatı' },
    { img: getImageByName('fensteli'), url: 'fens-teli-imalati', alt: 'Fens Teli İmalatı' },
    { img: getImageByName('telkafes'), url: 'punta-kaynakli-hasir-tel', alt: 'Punta Kaynaklı Hasır Tel' },
    { img: getImageByName('guvenlikbariyeri'), url: 'guvenlik-bariyeri-hasir-teli-imalati', alt: 'Güvenlik Bariyeri Hasır Tel' },
  ]
  return (
    <>
      <SEO
        title='Erez Kaynak - Çelik Hasır Tel ve Paslanmaz Filtre İmalatçısı - Hızlı ve Kaliteli Hizmet'
        description='Paslanmaz, galvanize yada çelik telden, tel kalınlığı ve ebat, göz aralığı fark etmeksizin punta kaynaklı hasır tel (çesan) imalatı yapılır.Paslanmaz elek teli ve paslanmaz filtre imalatçısı'
        keywords='hasır tel,hasır tel fiyatları,hasır tel imalatı,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı,filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek'
      />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Paslanmaz Kafes Teli İmalatı' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>standart çelik hasır tel imalatı</strong> ve istenilen göz aralığında, 2mm ile 8mm arasında istenilen tel
                kalınlığında ve istenilen her ebatlarda <strong>hasır tel imalatı</strong> yapılmaktadır.
              </p>
              <p>
                Malzeme kalitesi <strong>Siyah tel, Galvanize tel, 304 Paslanmaz tel</strong> ve <strong>316 Paslanmaz tel olarak</strong>{' '}
                müşteri tercihine göre kullanılarak imalat yapılmaktadır.
              </p>
              <p>Hızlı bilgi ve fiyat teklifi almak için iletişime geçebilirsiniz.</p>
              <Box textAlign='center'>
                <InnerLink title='İletişim Bilgileri' url='/iletisim' />
              </Box>
            </ContentText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <MediumImage lazyHeight={500} img={getImageByName('kafestel')} alt='çelik hasır tel imalatı erez kaynak' />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <SectionStyle1 bgColor='#fef9c7' hasBottom>
          <Grid container spacing={6} alignItems='center'>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
              <YoutubeWrapper>
                <MediumImage lazyHeight={300} img={getImageByName('youtubewrapper-1000')} alt='erez kaynak tanıtım' />
              </YoutubeWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ContentHeader title='Çelik Hasır ve Filtre Elek İmalatı' />
              <ContentText>
                <p>
                  Müşterilerimizin istekleri doğrultusunda istenilen şekil, numune ya da projeye göre <strong>filtre</strong>,{' '}
                  <strong>elek</strong> imalatı gerçekleştirmekteyiz.
                </p>
                <p>
                  İstenilen tel kalınlığında ve ebatında, göz aralığı fark etmeksizin <strong>punta kaynaklı hasır(çesan)</strong> imalatı
                  yapmaktayız. Stok ürün yada özel sipariş için iletişime geçiniz.
                </p>
              </ContentText>
            </Grid>
          </Grid>
        </SectionStyle1>
      </Box>
      <Box>
        <LinkCarousel imgArray={imgSlideArray} />
      </Box>
    </>
  )
}
export default IndexPage
